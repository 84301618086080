var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid grid-cols-12 gap-4" }, [
    _c(
      "div",
      { staticClass: "col-span-12" },
      _vm._l(_vm.alerts, function (alert, pos) {
        return _c(
          "vs-alert",
          {
            key: pos,
            class: alert.classes,
            attrs: { title: alert.title, color: alert.color },
          },
          [_c("span", [_vm._v(_vm._s(alert.text))])]
        )
      }),
      1
    ),
    _c(
      "div",
      { staticClass: "col-span-12" },
      [
        _c("vs-textarea", {
          attrs: { label: _vm.$t("data_viewer.keys.justification") },
          model: {
            value: _vm.content,
            callback: function ($$v) {
              _vm.content = $$v
            },
            expression: "content",
          },
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("content"),
                expression: "errors.has('content')",
              },
            ],
            staticClass: "text-danger text-sm",
          },
          [_vm._v(_vm._s(_vm.errors.first("content")))]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-span-12" },
      [
        _vm.showCancelButton
          ? _c(
              "vs-button",
              {
                staticClass: "float-right",
                attrs: { color: "primary", type: "border" },
                on: { click: _vm.cancel },
              },
              [_vm._v(_vm._s(_vm.$t("action.cancel")))]
            )
          : _vm._e(),
        _c(
          "vs-button",
          {
            staticClass: "float-right mr-2",
            attrs: { disabled: !_vm.validJustification },
            on: { click: _vm.save },
          },
          [_vm._v(_vm._s(_vm.$t("action.save")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }