var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.questionClasses }, [
    _c(
      "div",
      {
        staticClass: "w-full",
        staticStyle: { "border-bottom": "1px solid #dadce0" },
      },
      [
        _c("vs-icon", {
          class: [
            { "revision-color": _vm.revisionMarked },
            "select-none hover:animate-bounce transform hover:scale-125 float-right mr-1 pt-1",
            { "cursor-pointer": !_vm.disabled },
          ],
          attrs: {
            icon: _vm.revisionMarked ? "bookmark" : "bookmark_border",
            size: "1.5rem",
          },
          on: {
            click: function ($event) {
              return _vm.revisionMarkToggle()
            },
          },
        }),
        _c("div", { staticClass: "flex mb-2 mt-4 w-full select-none" }, [
          _c("div", { staticClass: "bg-grid-color-secondary mr-2 w-full" }, [
            _c("div", { staticClass: "m-1 flex gap-2" }, [
              _c("b", [
                _vm._v(_vm._s(_vm.$t("questao-this-index", [this.index]))),
              ]),
              !_vm.hide_score
                ? _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.item.data.score
                          ? `(${_vm.$t("valor")}: ` + _vm.formattedScore + ")"
                          : ""
                      )
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("div", {
              directives: [
                {
                  name: "html-safe",
                  rawName: "v-html-safe",
                  value: _vm.title,
                  expression: "title",
                },
              ],
              staticClass: "editor-content ck-content ml-3 mr-4 unselectable",
              attrs: { id: _vm.titleId },
            }),
          ]),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "flex sm:w-full" }, [
      _c(
        "div",
        { staticClass: "bg-grid-color-secondary ml-2 mr-2 mb-2 pt-2 w-full" },
        [
          _c("vs-input", {
            staticClass: "mb-6 mt-2",
            staticStyle: { width: "80%", margin: "0 auto" },
            attrs: { placeholder: "Título da redação" },
            on: {
              change: function ($event) {
                return _vm.valueChange()
              },
            },
            model: {
              value: _vm.value.title,
              callback: function ($$v) {
                _vm.$set(_vm.value, "title", $$v)
              },
              expression: "value.title",
            },
          }),
          _c("div", { staticClass: "notebook" }, [
            _c("div", { staticClass: "page" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.value.content,
                    expression: "value.content",
                  },
                ],
                domProps: { value: _vm.value.content },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.value, "content", $event.target.value)
                    },
                    function ($event) {
                      return _vm.calcTextDetails($event.target.value)
                    },
                  ],
                  change: function ($event) {
                    return _vm.valueChange()
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "text-details" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-row justify-between rounded-t-sm text-white",
                },
                [
                  _c("p", [
                    _vm._v(
                      " Caracteres: " + _vm._s(_vm.textDetail.characteres) + " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(" Palavras: " + _vm._s(_vm.textDetail.words) + " "),
                  ]),
                  _c(
                    "p",
                    {
                      staticClass:
                        "MuiTypography-root MuiTypography-body1 css-t5g5se",
                    },
                    [_vm._v(" Frases: " + _vm._s(_vm.textDetail.phrases) + " ")]
                  ),
                  _c("p", [
                    _vm._v(
                      " Parágrafos: " + _vm._s(_vm.textDetail.paragraphs) + " "
                    ),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "flex flex-wrap items-center mt-4" }, [
              _c(
                "div",
                { staticClass: "flex w-full justify-center font-bold" },
                [_vm._v(" Estatísticas ")]
              ),
              _c("div", { staticClass: "flex mb-4 mt-2 w-full" }, [
                _c(
                  "div",
                  {
                    staticClass: "bg-grid-color-secondary mr-2",
                    staticStyle: { width: "100%" },
                  },
                  [
                    _vm._m(0),
                    _c("div", { staticClass: "grid-cols-4 flex" }, [
                      _c("div", { staticClass: "w-3/12 font-bold" }, [
                        _vm._v(" Qtd. Palavras: "),
                      ]),
                      _c("div", { staticClass: "w-1/12" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.item.data.limits.words.min
                                ? _vm.item.data.limits.words.min
                                : "-"
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "w-1/12" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.item.data.limits.words.max
                                ? _vm.item.data.limits.words.max
                                : "-"
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "w-7/12" }, [
                        _vm.maxExceeded(
                          _vm.item.data.limits.words.max,
                          _vm.textDetail.words
                        )
                          ? _c(
                              "span",
                              { staticClass: "inline-flex items-center" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { "padding-top": "2px" },
                                  attrs: {
                                    icon: "AlertTriangleIcon",
                                    svgClasses:
                                      "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                                  },
                                }),
                                _c("span", [
                                  _vm._v("Máximo de palavras atingido."),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.minNotReached(
                          _vm.item.data.limits.words.min,
                          _vm.textDetail.words
                        )
                          ? _c(
                              "span",
                              { staticClass: "inline-flex items-center" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { "padding-top": "2px" },
                                  attrs: {
                                    icon: "AlertTriangleIcon",
                                    svgClasses:
                                      "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                                  },
                                }),
                                _c("span", [
                                  _vm._v(
                                    "Mínimo de palavras ainda não atingido."
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.maxExceeded(
                          _vm.item.data.limits.words.max,
                          _vm.textDetail.words
                        ) &&
                        !_vm.minNotReached(
                          _vm.item.data.limits.words.min,
                          _vm.textDetail.words
                        )
                          ? _c(
                              "span",
                              { staticClass: "inline-flex items-center" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { "padding-top": "2px" },
                                  attrs: {
                                    icon: "CheckIcon",
                                    svgClasses:
                                      "h-5 w-5 mb-1 mr-3 stroke-current text-success",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "grid-cols-4 flex" }, [
                      _c("div", { staticClass: "w-3/12 font-bold" }, [
                        _vm._v(" Qtd. Frases: "),
                      ]),
                      _c("div", { staticClass: "w-1/12" }, [
                        _vm._v(
                          " " + _vm._s(_vm.item.data.limits.phrases.min) + " "
                        ),
                      ]),
                      _c("div", { staticClass: "w-1/12" }, [
                        _vm._v(
                          " " + _vm._s(_vm.item.data.limits.phrases.max) + " "
                        ),
                      ]),
                      _c("div", { staticClass: "w-7/12" }, [
                        _vm.maxExceeded(
                          _vm.item.data.limits.phrases.max,
                          _vm.textDetail.phrases
                        )
                          ? _c(
                              "span",
                              { staticClass: "inline-flex items-center" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { "padding-top": "2px" },
                                  attrs: {
                                    icon: "AlertTriangleIcon",
                                    svgClasses:
                                      "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                                  },
                                }),
                                _c("span", [
                                  _vm._v("Máximo de frases atingido."),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.minNotReached(
                          _vm.item.data.limits.phrases.min,
                          _vm.textDetail.phrases
                        )
                          ? _c(
                              "span",
                              { staticClass: "inline-flex items-center" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { "padding-top": "2px" },
                                  attrs: {
                                    icon: "AlertTriangleIcon",
                                    svgClasses:
                                      "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                                  },
                                }),
                                _c("span", [
                                  _vm._v(
                                    "Mínimo de frases ainda não atingido."
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.maxExceeded(
                          _vm.item.data.limits.phrases.max,
                          _vm.textDetail.phrases
                        ) &&
                        !_vm.minNotReached(
                          _vm.item.data.limits.phrases.min,
                          _vm.textDetail.phrases
                        )
                          ? _c(
                              "span",
                              { staticClass: "inline-flex items-center" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { "padding-top": "2px" },
                                  attrs: {
                                    icon: "CheckIcon",
                                    svgClasses:
                                      "h-5 w-5 mb-1 mr-3 stroke-current text-success",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "grid-cols-4 flex" }, [
                      _c("div", { staticClass: "w-3/12 font-bold" }, [
                        _vm._v(" Qtd. Parágrafos: "),
                      ]),
                      _c("div", { staticClass: "w-1/12" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.item.data.limits.paragraphs.min) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "w-1/12" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.item.data.limits.paragraphs.max) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "w-7/12" }, [
                        _vm.maxExceeded(
                          _vm.item.data.limits.paragraphs.max,
                          _vm.textDetail.paragraphs
                        )
                          ? _c(
                              "span",
                              { staticClass: "inline-flex items-center" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { "padding-top": "2px" },
                                  attrs: {
                                    icon: "AlertTriangleIcon",
                                    svgClasses:
                                      "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                                  },
                                }),
                                _c("span", [
                                  _vm._v("Máximo de parágrafos atingido."),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.minNotReached(
                          _vm.item.data.limits.paragraphs.min,
                          _vm.textDetail.paragraphs
                        )
                          ? _c(
                              "span",
                              { staticClass: "inline-flex items-center" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { "padding-top": "2px" },
                                  attrs: {
                                    icon: "AlertTriangleIcon",
                                    svgClasses:
                                      "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                                  },
                                }),
                                _c("span", [
                                  _vm._v(
                                    "Mínimo de parágrafos ainda não atingido."
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.maxExceeded(
                          _vm.item.data.limits.paragraphs.max,
                          _vm.textDetail.paragraphs
                        ) &&
                        !_vm.minNotReached(
                          _vm.item.data.limits.paragraphs.min,
                          _vm.textDetail.paragraphs
                        )
                          ? _c(
                              "span",
                              { staticClass: "inline-flex items-center" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { "padding-top": "2px" },
                                  attrs: {
                                    icon: "CheckIcon",
                                    svgClasses:
                                      "h-5 w-5 mb-1 mr-3 stroke-current text-success",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("value"),
                  expression: "errors.has('value')",
                },
              ],
              staticClass: "text-danger text-sm",
            },
            [_vm._v(_vm._s(_vm.errors.first("value")))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "grid-cols-4 flex" }, [
      _c("div", { staticClass: "w-3/12" }),
      _c("div", { staticClass: "w-1/12" }, [
        _c("span", { staticClass: "font-bold" }, [_vm._v("Minímo")]),
      ]),
      _c("div", { staticClass: "w-1/12" }, [
        _c("span", { staticClass: "font-bold" }, [_vm._v("Máximo")]),
      ]),
      _c("div", { staticClass: "w-7/12" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }