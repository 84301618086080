var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vs-con-loading__container flex justify-center align-middle",
      attrs: { id: "logo-container" },
    },
    [
      _vm.institutionLogo
        ? _c("img", {
            staticClass:
              "border-faint-grey border-solid border-l pl-2 border-t-0 border-b-0 border-r-0",
            staticStyle: { "max-width": "100px" },
            attrs: {
              src: _vm.institutionLogo,
              alt: _vm.$t("oganization-and-and-organization-name-or-or"),
            },
          })
        : !this.reduce && _vm.getLogo
        ? _c("img", {
            staticClass: "mx-auto",
            style: _vm.logoStyleStr,
            attrs: { src: _vm.getLogo, alt: "login" },
          })
        : _vm.smallLogo
        ? _c("img", {
            staticClass: "mx-auto",
            style: _vm.logoStyleStr,
            attrs: { src: _vm.smallLogo, alt: "login" },
          })
        : _c("img", {
            attrs: {
              src: require("@/assets/images/picture-placeholder.png"),
              width: "50",
            },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }