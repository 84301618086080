var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.questionClasses }, [
    _c(
      "div",
      {
        staticClass: "w-full mb-4",
        staticStyle: { "border-bottom": "1px solid #dadce0" },
      },
      [
        _c("vs-icon", {
          class: [
            { "revision-color": _vm.revisionMarked },
            "select-none hover:animate-bounce transform hover:scale-125 float-right mr-1 pt-1",
            { "cursor-pointer": !_vm.disabled },
          ],
          attrs: {
            icon: _vm.revisionMarked ? "bookmark" : "bookmark_border",
            size: "1.5rem",
          },
          on: {
            click: function ($event) {
              return _vm.revisionMarkToggle()
            },
          },
        }),
        _c("div", { staticClass: "flex mb-2 ml-4 mt-4 w-full select-none" }, [
          _c("div", { staticClass: "bg-grid-color-secondary mr-2 w-full" }, [
            _c("div", { staticClass: "mb-2 flex gap-2" }, [
              _c("b", [
                _vm._v(_vm._s(_vm.$t("questao-this-index", [this.index]))),
              ]),
              !_vm.hide_score
                ? _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.item.data.score
                          ? `(${_vm.$t("valor")}: ` + _vm.formattedScore + ")"
                          : ""
                      )
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("div", {
              directives: [
                {
                  name: "html-safe",
                  rawName: "v-html-safe",
                  value: _vm.title,
                  expression: "title",
                },
              ],
              staticClass: "editor-content ck-content m-2 mr-4 unselectable",
              attrs: { id: _vm.titleId },
            }),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { key: _vm.key, staticClass: "options m-4 sm:m-2 md:m-2 lg:-m-2 xl:m-2" },
      [
        _vm.inlineOptions
          ? _c(
              "div",
              { staticClass: "flex gap-3 sm:gap-1 sm:py-2 flex-col" },
              _vm._l(_vm.options, function (option, index) {
                return _c(
                  "vs-row",
                  { key: index },
                  [
                    _vm.isEnumerated
                      ? _c(
                          "vs-col",
                          {
                            staticClass: "flex items-center justify-center",
                            staticStyle: { width: "20px" },
                            attrs: {
                              "vs-type": "flex",
                              "vs-justify": "center",
                              "vs-align": "left",
                              "vs-w": "1",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.enumerateLabel(index)) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "vs-col",
                      {
                        staticClass: "flex items-center",
                        staticStyle: { width: "50px" },
                        attrs: {
                          "vs-type": "flex",
                          "vs-justify": "center",
                          "vs-align": "left",
                          "vs-w": "1",
                        },
                      },
                      [
                        _c("vs-button", {
                          class: option.selected
                            ? "choose-button checked-button"
                            : "choose-button",
                          attrs: {
                            type: "filled",
                            icon: option.selected
                              ? "radio_button_checked"
                              : "radio_button_unchecked",
                            disabled: _vm.disabled,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.optionSelected(option)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      {
                        staticStyle: { width: "auto !important" },
                        attrs: {
                          "vs-type": "flex",
                          "vs-justify": "left",
                          "vs-align": "center",
                        },
                      },
                      [
                        _c("div", {
                          directives: [
                            {
                              name: "html-safe",
                              rawName: "v-html-safe",
                              value: option.label,
                              expression: "option.label",
                            },
                          ],
                          class: [
                            { "cursor-pointer": !_vm.disabled },
                            "select-none editor-content ck-content _option-text",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.optionSelected(option)
                            },
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                )
              }),
              1
            )
          : _c(
              "div",
              _vm._l(_vm.chunkedOptions, function (opts, i) {
                return _c(
                  "div",
                  { key: opts, class: _vm.optionsGridClass },
                  _vm._l(opts, function (option, index) {
                    return _c(
                      "vs-row",
                      { key: option, staticClass: "mt-2" },
                      [
                        _c(
                          "vs-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-type": "flex",
                                  "vs-justify": "center",
                                  "vs-align": "center",
                                  "vs-w": "12",
                                },
                              },
                              [
                                _vm.isEnumerated
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex items-center justify-center",
                                        staticStyle: { width: "20px" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.enumerateLabel(
                                                i * _vm.chunkQuantity + index
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "vs-row",
                          [
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-type": "flex",
                                  "vs-justify": "center",
                                  "vs-align": "center",
                                  "vs-w": "12",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "cursor-pointer",
                                    staticStyle: {
                                      "justify-content": "center",
                                      display: "flex",
                                      "flex-direction": "row",
                                      overflow: "hidden",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.optionSelected(option)
                                      },
                                    },
                                  },
                                  [
                                    option.image && option.image.src
                                      ? _c("img", {
                                          staticStyle: {
                                            height: "auto",
                                            width: "auto",
                                            "max-width": "200px",
                                            "max-height": "200px",
                                          },
                                          attrs: { src: option.image.src },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-type": "flex",
                                  "vs-justify": "center",
                                  "vs-align": "center",
                                  "vs-w": "12",
                                },
                              },
                              [
                                _c("vs-button", {
                                  staticClass: "cursor-pointer",
                                  class: option.selected
                                    ? "choose-button checked-button"
                                    : "choose-button",
                                  attrs: {
                                    type: "filled",
                                    icon: option.selected
                                      ? "radio_button_checked"
                                      : "radio_button_unchecked",
                                    disabled: _vm.disabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.optionSelected(option)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-type": "flex",
                                  "vs-justify": "center",
                                  "vs-align": "center",
                                  "vs-w": "12",
                                },
                              },
                              [
                                _c("div", {
                                  directives: [
                                    {
                                      name: "html-safe",
                                      rawName: "v-html-safe",
                                      value: option.label,
                                      expression: "option.label",
                                    },
                                  ],
                                  staticClass:
                                    "cursor-pointer editor-content ck-content unselectable",
                                  on: {
                                    click: function ($event) {
                                      return _vm.optionSelected(option)
                                    },
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                )
              }),
              0
            ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }