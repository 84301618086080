var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "gap-1 flex-grow flex flex-wrap flex-row items-center justify-between w-full",
    },
    [
      _c(
        "div",
        { staticStyle: { "min-width": "90px", "max-width": "105px" } },
        [
          !_vm.reduced
            ? _c(
                "vs-button",
                {
                  staticClass: "footer-button p-3 w-full",
                  attrs: {
                    type: "filled",
                    color: "warning",
                    icon: "keyboard_arrow_left",
                    disabled: _vm.disablePrevious,
                  },
                  on: { click: _vm.emitPrevious },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
              )
            : _c("vs-button", {
                staticClass: "footer-button p-3 w-full",
                attrs: {
                  type: "filled",
                  color: "warning",
                  icon: "keyboard_arrow_left",
                  disabled: _vm.disablePrevious,
                },
                on: { click: _vm.emitPrevious },
              }),
        ],
        1
      ),
      _c("div", { staticClass: "xl:w-auto" }, [
        _c("div", { staticClass: "grid w-full justify-items-center" }, [
          _vm._v(" " + _vm._s(_vm.paginationInfo) + " "),
        ]),
      ]),
      _c(
        "div",
        { staticStyle: { "min-width": "90px", "max-width": "105px" } },
        [
          !_vm.reduced
            ? _c(
                "vs-button",
                {
                  staticClass:
                    "footer-button buttonnext vs-con-loading__container p-3 w-full",
                  attrs: {
                    color: _vm.showDone ? "success" : "primary",
                    type: "filled",
                    icon: "keyboard_arrow_right",
                    disabled: _vm.disableNext,
                  },
                  on: { click: _vm.emitNext },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.showDone ? _vm.$t("enviar") : _vm.$t("avancar")
                      ) +
                      " "
                  ),
                ]
              )
            : _c("vs-button", {
                staticClass:
                  "footer-button buttonnext vs-con-loading__container p-3 w-full",
                attrs: {
                  color: _vm.showDone ? "success" : "primary",
                  type: "filled",
                  icon: _vm.showDone ? "send" : "keyboard_arrow_right",
                  disabled: _vm.disableNext,
                },
                on: { click: _vm.emitNext },
              }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }