var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.questionClasses }, [
    _c(
      "div",
      {
        staticClass: "w-full",
        staticStyle: { "border-bottom": "1px solid #dadce0" },
      },
      [
        _c("vs-icon", {
          class: [
            { "revision-color": _vm.revisionMarked },
            "select-none hover:animate-bounce transform hover:scale-125 float-right mr-1 pt-1",
            { "cursor-pointer": !_vm.disabled },
          ],
          attrs: {
            icon: _vm.revisionMarked ? "bookmark" : "bookmark_border",
            size: "1.5rem",
          },
          on: {
            click: function ($event) {
              return _vm.revisionMarkToggle()
            },
          },
        }),
        _c(
          "div",
          { staticClass: "flex flex-col pb-2 pt-4 w-full select-none" },
          [
            _c("div", { staticClass: "p-2 flex gap-2" }, [
              _c("b", [
                _vm._v(_vm._s(_vm.$t("questao-this-index", [this.index]))),
              ]),
              !_vm.hide_score
                ? _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.item.data.score
                          ? `(${_vm.$t("valor")}: ` + _vm.formattedScore + ")"
                          : ""
                      )
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("div", {
              directives: [
                {
                  name: "html-safe",
                  rawName: "v-html-safe",
                  value: _vm.title,
                  expression: "title",
                },
              ],
              staticClass: "editor-content ck-content p-2",
              attrs: { id: _vm.titleId },
            }),
          ]
        ),
      ],
      1
    ),
    _c("div", { key: _vm.item.id, staticClass: "flex sm:w-full" }, [
      _c(
        "div",
        { staticClass: "ml-2 mr-2 mb-2 pt-2 w-full" },
        [
          _vm.disabled
            ? _c(
                "div",
                { staticClass: "flex flex-wrap justify-center gap-drop" },
                _vm._l(_vm.dragItems, function (item) {
                  return _c("div", { key: item }, [
                    _c(
                      "div",
                      {
                        class: [
                          "drag-item flex flex-wrap select-none",
                          { "drag-item-disabled": _vm.disabled },
                        ],
                      },
                      [
                        _c("div", { class: { handle: !_vm.disabled } }, [
                          _vm._v(" " + _vm._s(item.label) + " "),
                        ]),
                      ]
                    ),
                  ])
                }),
                0
              )
            : _c(
                "drop-list",
                {
                  staticClass: "flex flex-wrap justify-center gap-drop",
                  attrs: { items: _vm.dragItems, mode: "cut" },
                  on: { insert: _vm.insertOptions },
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "drag",
                            {
                              key: item,
                              attrs: { data: item, handle: ".handle" },
                              on: {
                                cut: function ($event) {
                                  return _vm.remove(_vm.dragItems, item)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: [
                                    "drag-item flex flex-wrap select-none",
                                    { "drag-item-disabled": _vm.disabled },
                                  ],
                                },
                                [
                                  _c(
                                    "div",
                                    { class: { handle: !_vm.disabled } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex items-center" },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "vs-icon notranslate icon-scale vs-button--icon material-icons mr-2",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            },
                                            [_vm._v(" drag_indicator ")]
                                          ),
                                          _vm._v(
                                            " " + _vm._s(item.label) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "feedback",
                      fn: function ({ data }) {
                        return [
                          _c(
                            "div",
                            {
                              key: data,
                              staticClass: "magnetic-gap-drop-item-enter mt-4",
                            },
                            [_vm._v(" " + _vm._s(data.label) + " ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("div", { staticStyle: { height: "200px" } })]
              ),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-center mb-6 mt-4" },
            _vm._l(_vm.gaps, function (gap, index) {
              return _c("div", { key: gap }, [
                gap.type === "magnetic_gap" && gap.droped
                  ? _c(
                      "div",
                      { staticClass: "mt-4" },
                      [
                        _c("drop-list", {
                          attrs: { items: [gap] },
                          on: {
                            dragenter: function ($event) {
                              return _vm.dragEnter(gap)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "drag",
                                      {
                                        key: item,
                                        attrs: {
                                          data: item,
                                          handle: ".handle",
                                        },
                                        on: {
                                          cut: function ($event) {
                                            return _vm.remove(_vm.gaps, item)
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "drag-image",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "magnetic-gap-drop-item-enter",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.label) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            class: [
                                              "magnetic-gap-drop-item flex flex-wrap",
                                              { handle: !_vm.disabled },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.label) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "feedback",
                                fn: function ({ data }) {
                                  return [
                                    _c("div", {
                                      key: data,
                                      staticClass: "item feedback",
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                gap.type === "magnetic_gap" && !gap.droped
                  ? _c(
                      "div",
                      [
                        _c("drop-list", {
                          attrs: { items: [gap], mode: "cut" },
                          on: {
                            insert: function ($event) {
                              return _vm.insertGaps($event, index)
                            },
                            dragenter: function ($event) {
                              return _vm.dragEnter(gap)
                            },
                            dragleave: function ($event) {
                              return _vm.dragLeave(gap)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "drag",
                                      {
                                        key: item,
                                        attrs: {
                                          data: item,
                                          handle: ".handle",
                                        },
                                        on: {
                                          cut: function ($event) {
                                            return _vm.remove(_vm.gaps, item)
                                          },
                                        },
                                      },
                                      [
                                        item.droped
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "magnetic-gap-drop-item-enter mt-4",
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.label) + " "
                                                ),
                                              ]
                                            )
                                          : !item.enter
                                          ? _c("div", {
                                              class: [
                                                {
                                                  "magnetic-gap-drop-item mt-4":
                                                    item.type ===
                                                    "magnetic_gap",
                                                },
                                                "flex flex-wrap mt-4",
                                              ],
                                            })
                                          : _c("div", {
                                              staticClass:
                                                "magnetic-gap-drop-item-enter mt-4",
                                            }),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "feedback",
                                fn: function ({ data }) {
                                  return [
                                    _c("div", {
                                      key: data,
                                      staticClass: "item feedback",
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                gap.type === "gap"
                  ? _c(
                      "div",
                      { staticClass: "gap-drop-item mt-4" },
                      [
                        !_vm.disabled
                          ? _c("TitleComponent", {
                              attrs: {
                                debounceUpdate: false,
                                item: gap,
                                type: "gap",
                              },
                              on: {
                                "title-TITLE_UPDATED": _vm.updateOptionTitle,
                              },
                            })
                          : _vm._e(),
                        _vm.disabled
                          ? _c("span", [_vm._v(" " + _vm._s(gap.answer) + " ")])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                gap.type === "text"
                  ? _c("div", { staticClass: "mt-4" }, [
                      _vm._v(" " + _vm._s(gap.label) + " "),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }