var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.viewAlert
      ? _c(
          "div",
          { staticClass: "flex justify-items-center" },
          [
            _c(
              "vs-alert",
              {
                staticClass: "mt-5 text-warning mb-4 h-auto",
                attrs: {
                  title: _vm.$t("alerta"),
                  color: "rgb(231, 154, 23)",
                  active: "true",
                },
              },
              [
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("document_person.required_description")) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "w-full grid justify-items-center" },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "float-left mt-1 hover:scale-110",
                        attrs: { type: "flat" },
                        on: { click: _vm.openPopup },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("document_person.touch_here_to_start")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c("DocumentQrCodeUploadPopup", {
              attrs: { qrCodeData: _vm.qrCodeData, visible: _vm.showPopup },
              on: { close: _vm.closePopup },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }