import { render, staticRenderFns } from "./GeneralInformation.vue?vue&type=template&id=733b3a9a&scoped=true"
import script from "./GeneralInformation.vue?vue&type=script&lang=js"
export * from "./GeneralInformation.vue?vue&type=script&lang=js"
import style0 from "./GeneralInformation.vue?vue&type=style&index=0&id=733b3a9a&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "733b3a9a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/user/workspace/educatena/educatena-app-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('733b3a9a')) {
      api.createRecord('733b3a9a', component.options)
    } else {
      api.reload('733b3a9a', component.options)
    }
    module.hot.accept("./GeneralInformation.vue?vue&type=template&id=733b3a9a&scoped=true", function () {
      api.rerender('733b3a9a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/questionnaires/answers/GeneralInformation.vue"
export default component.exports