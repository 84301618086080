var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "formAnswer",
      class: [
        "form-answer relative min-h-screen",
        { "active-sidebar": _vm.activeSidebar },
      ],
    },
    [
      _c(
        "questionnaire-event-overseer",
        {
          staticClass: "min-h-screen",
          attrs: {
            screenfull: _vm.screenfullInstance,
            recording: _vm.isRecordingEvents,
            answerId: _vm.answerId,
            service: _vm.questionnaireAnswerService,
          },
          on: {
            queueSizeChanged: function ($event) {
              _vm.eventQueueSize = $event
            },
          },
        },
        [
          _vm.preview
            ? _c(
                "vs-row",
                { staticClass: "mt-4", attrs: { "vs-justify": "center" } },
                [
                  _vm.preview
                    ? _c(
                        "vs-row",
                        {
                          staticClass: "mt-4 overflow-hidden",
                          attrs: { "vs-justify": "center" },
                        },
                        [
                          _c(
                            "vs-col",
                            {
                              attrs: {
                                type: "flex",
                                "vs-justify": "center",
                                "vs-align": "center",
                                "vs-w": "11",
                              },
                            },
                            [
                              _c("vs-card", { attrs: { actionable: "" } }, [
                                _c(
                                  "div",
                                  { attrs: { slot: "header" }, slot: "header" },
                                  [
                                    _c(
                                      "vs-alert",
                                      {
                                        staticClass: "text-warning h-auto",
                                        attrs: {
                                          title: _vm.$t(
                                            "questionnaire.warn_preview"
                                          ),
                                          color: "rgb(231, 154, 23)",
                                          active: "true",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "questionnaire.warn_preview_full"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          !_vm.started
            ? _c(
                "vs-row",
                { staticClass: "mt-2", attrs: { "vs-justify": "center" } },
                [
                  _c(
                    "vs-col",
                    {
                      attrs: {
                        type: "flex",
                        "vs-justify": "center",
                        "vs-align": "center",
                        "vs-w": "11",
                      },
                    },
                    [
                      _vm.questionnaire !== null
                        ? _c("vs-card", [
                            _c(
                              "div",
                              { attrs: { slot: "header" }, slot: "header" },
                              [
                                _c("GeneralInformation", {
                                  attrs: {
                                    preview: _vm.preview,
                                    start_error_code: _vm.startErrorCode,
                                    start_error: _vm.startError,
                                    questionnaire: _vm.questionnaire,
                                    show: {
                                      duration_shortage: _vm.durationShortage,
                                    },
                                    voiceControlActivated:
                                      _vm.voiceControlActivated,
                                  },
                                  on: {
                                    toggleVoiceControl:
                                      _vm.voiceControlStateChange,
                                  },
                                }),
                                _vm.isDocumentPersonOnBoard
                                  ? _c("document-person-on-board", {
                                      attrs: {
                                        facematch: _vm._.get(
                                          _vm.questionnaire,
                                          "facematch",
                                          false
                                        ),
                                        liveness: _vm._.get(
                                          _vm.questionnaire,
                                          "liveness",
                                          false
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm.description
                                  ? _c("div", {
                                      directives: [
                                        {
                                          name: "html-safe",
                                          rawName: "v-html-safe",
                                          value: _vm.description,
                                          expression: "description",
                                        },
                                      ],
                                      staticClass: "editor-content ck-content",
                                      attrs: { id: "descriptionElement" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showStart && !_vm.started && !_vm.startError
            ? _c(
                "div",
                {
                  staticClass:
                    "flex justify-items-center justify-center w-full mb-4 mt-4",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-span-12 flex justify-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "w-full sm:w-auto" },
                        [
                          _vm.showPassword
                            ? _c("vs-input", {
                                staticClass: "no-icon-border w-full",
                                attrs: {
                                  type: "password",
                                  name: "questionnaire_password",
                                  icon: "icon icon-lock",
                                  "icon-pack": "feather",
                                  "label-placeholder": "Código/Senha",
                                  autocomplete: "off",
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm.showStart && !_vm.started
            ? _c("div", { staticClass: "flex justify-center" }, [
                _c("div", { staticClass: "flex flex-col gap-2" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid w-full gap-2 auto-cols-auto sm:auto-cols-min sm:justify-center",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex gap-2 sm:w-auto" },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass:
                                "footer-button w-full md:w-auto start-questionnaire",
                              attrs: {
                                type: "filled",
                                icon: "play_arrow",
                                disabled: _vm.disableStartButton,
                              },
                              on: { click: _vm.start },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  this.continue
                                    ? _vm.$t("continuar")
                                    : _vm.$t("iniciar")
                                )
                              ),
                            ]
                          ),
                          !_vm.started && _vm.questionnaire
                            ? _c(
                                "vs-button",
                                {
                                  staticClass: "footer-button w-full md:w-auto",
                                  attrs: { type: "border", icon: "arrow_back" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.push("/")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("common.back")))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  !_vm.started
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "w-full flex items-center justify-center gap-2 mt-2",
                        },
                        [
                          _c("logo", {
                            staticStyle: { "max-width": "100px" },
                            attrs: { alt: "$t('educatena')" },
                          }),
                          _vm.organizationLogo
                            ? _c("img", {
                                staticClass:
                                  "border-faint-grey border-solid border-l pl-2 border-t-0 border-b-0 border-r-0",
                                staticStyle: { "max-width": "100px" },
                                attrs: {
                                  src: _vm.organizationLogo,
                                  alt: _vm.$t(
                                    "oganization-and-and-organization-name-or-or"
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm.started && !_vm.hasTemplate
            ? _c(
                "div",
                {
                  ref: "parentSidebar",
                  class: [
                    "grid grid-cols-1 sm:grid-cols-12",
                    { "min-h-screen": _vm.showSingleQuestion },
                  ],
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        "pb-20",
                        "grid col-span-1 mr-4",
                        _vm.activeSidebar
                          ? "sm:col-span-6 md:col-span-6 lg:col-span-9"
                          : "sm:col-span-12",
                      ],
                    },
                    [
                      _vm.started && !_vm.showSingleQuestion
                        ? _c(
                            "vs-row",
                            { staticClass: "mt-2 items-center justify-center" },
                            [
                              _c("vs-col", { staticClass: "mr-4 ml-4 mt-2" }, [
                                _c(
                                  "div",
                                  _vm._l(
                                    _vm.dataFields(),
                                    function (field, index) {
                                      return _c(
                                        "vs-row",
                                        { key: field.id },
                                        [
                                          _c(
                                            "vs-col",
                                            {
                                              class:
                                                _vm.sectionBackground(field),
                                              attrs: {
                                                "vs-type": "flex",
                                                "vs-justify": "left",
                                                "vs-align": "left",
                                                "vs-w": "12",
                                              },
                                              on: {
                                                click: _vm.clickedQuestion,
                                              },
                                            },
                                            [
                                              field.type === "section"
                                                ? _c("SectionAnswer", {
                                                    attrs: {
                                                      answer_id:
                                                        _vm.questionnaireAnswer
                                                          .id,
                                                      user_id: _vm.user_id,
                                                      item: field,
                                                      service:
                                                        _vm.questionnaireAnswerService,
                                                    },
                                                  })
                                                : _vm._e(),
                                              field.type === "simple_text"
                                                ? _c("SimpleTextAnswer", {
                                                    attrs: {
                                                      revisionMarked: _vm._.get(
                                                        _vm.bookmarks,
                                                        field.id,
                                                        false
                                                      ),
                                                      answer_id:
                                                        _vm.questionnaireAnswer
                                                          .id,
                                                      user_id: _vm.user_id,
                                                      item: field,
                                                      service:
                                                        _vm.questionnaireAnswerService,
                                                      show_required_indicator:
                                                        _vm.showQuestionAsRequired(
                                                          field.id
                                                        ),
                                                      preview: _vm.preview,
                                                      hide_score:
                                                        _vm.hideQuestionScore,
                                                      index:
                                                        _vm.questionNumber(
                                                          index
                                                        ),
                                                    },
                                                    on: {
                                                      revisionMarked:
                                                        _vm.revisionMarked,
                                                    },
                                                  })
                                                : _vm._e(),
                                              field.type === "multiple_choice"
                                                ? _c("MultipleChoiceAnswer", {
                                                    attrs: {
                                                      bus: _vm.eventBus,
                                                      revisionMarked: _vm._.get(
                                                        _vm.bookmarks,
                                                        field.id,
                                                        false
                                                      ),
                                                      answer_id:
                                                        _vm.questionnaireAnswer
                                                          .id,
                                                      user_id: _vm.user_id,
                                                      item: field,
                                                      service:
                                                        _vm.questionnaireAnswerService,
                                                      show_required_indicator:
                                                        _vm.showQuestionAsRequired(
                                                          field.id
                                                        ),
                                                      preview: _vm.preview,
                                                      index:
                                                        _vm.questionNumber(
                                                          index
                                                        ),
                                                      hide_score:
                                                        _vm.hideQuestionScore,
                                                    },
                                                    on: {
                                                      revisionMarked:
                                                        _vm.revisionMarked,
                                                    },
                                                  })
                                                : _vm._e(),
                                              field.type === "check_box"
                                                ? _c("CheckBoxAnswer", {
                                                    attrs: {
                                                      revisionMarked: _vm._.get(
                                                        _vm.bookmarks,
                                                        field.id,
                                                        false
                                                      ),
                                                      answer_id:
                                                        _vm.questionnaireAnswer
                                                          .id,
                                                      user_id: _vm.user_id,
                                                      item: field,
                                                      service:
                                                        _vm.questionnaireAnswerService,
                                                      show_required_indicator:
                                                        _vm.showQuestionAsRequired(
                                                          field.id
                                                        ),
                                                      preview: _vm.preview,
                                                      index:
                                                        _vm.questionNumber(
                                                          index
                                                        ),
                                                      hide_score:
                                                        _vm.hideQuestionScore,
                                                    },
                                                    on: {
                                                      revisionMarked:
                                                        _vm.revisionMarked,
                                                    },
                                                  })
                                                : _vm._e(),
                                              field.type === "gap"
                                                ? _c("GapAnswer", {
                                                    attrs: {
                                                      revisionMarked: _vm._.get(
                                                        _vm.bookmarks,
                                                        field.id,
                                                        false
                                                      ),
                                                      answer_id:
                                                        _vm.questionnaireAnswer
                                                          .id,
                                                      user_id: _vm.user_id,
                                                      item: field,
                                                      service:
                                                        _vm.questionnaireAnswerService,
                                                      show_required_indicator:
                                                        _vm.showQuestionAsRequired(
                                                          field.id
                                                        ),
                                                      preview: _vm.preview,
                                                      hide_score:
                                                        _vm.hideQuestionScore,
                                                      index:
                                                        _vm.questionNumber(
                                                          index
                                                        ),
                                                    },
                                                    on: {
                                                      revisionMarked:
                                                        _vm.revisionMarked,
                                                    },
                                                  })
                                                : _vm._e(),
                                              field.type === "essay"
                                                ? _c("EssayAnswer", {
                                                    attrs: {
                                                      revisionMarked: _vm._.get(
                                                        _vm.bookmarks,
                                                        field.id,
                                                        false
                                                      ),
                                                      answer_id:
                                                        _vm.questionnaireAnswer
                                                          .id,
                                                      user_id: _vm.user_id,
                                                      item: field,
                                                      service:
                                                        _vm.questionnaireAnswerService,
                                                      show_required_indicator:
                                                        _vm.showQuestionAsRequired(
                                                          field.id
                                                        ),
                                                      preview: _vm.preview,
                                                      hide_score:
                                                        _vm.hideQuestionScore,
                                                      index:
                                                        _vm.questionNumber(
                                                          index
                                                        ),
                                                    },
                                                    on: {
                                                      revisionMarked:
                                                        _vm.revisionMarked,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.started && _vm.showSingleQuestion
                        ? _c(
                            "vs-row",
                            {
                              key: _vm.key,
                              staticClass:
                                "mt-2 items-center justify-center min-h-screen",
                            },
                            [
                              _c(
                                "vs-col",
                                { staticClass: "mr-4 ml-4 mt-2" },
                                [
                                  _c(
                                    "vx-card",
                                    {
                                      staticClass: "__answer-card",
                                      on: { click: _vm.clickedQuestion },
                                    },
                                    [
                                      _vm._.get(_vm.presentedField, "type") ===
                                      "section"
                                        ? _c("SectionAnswer", {
                                            ref: "currentSingleQuestion",
                                            staticClass:
                                              "flex w-full items-center justify-center",
                                            attrs: {
                                              answer_id:
                                                _vm.questionnaireAnswer.id,
                                              user_id: _vm.user_id,
                                              item: _vm.presentedField,
                                              service:
                                                _vm.questionnaireAnswerService,
                                              hide_score: _vm.hideQuestionScore,
                                              show_required_indicator:
                                                _vm.presentedField
                                                  .showRequiredIndicator,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._.get(_vm.presentedField, "type") ===
                                      "simple_text"
                                        ? _c("SimpleTextAnswer", {
                                            ref: "currentSingleQuestion",
                                            attrs: {
                                              revisionMarked: _vm._.get(
                                                _vm.bookmarks,
                                                _vm.presentedField.id,
                                                false
                                              ),
                                              answer_id:
                                                _vm.questionnaireAnswer.id,
                                              user_id: _vm.user_id,
                                              item: _vm.presentedField,
                                              service:
                                                _vm.questionnaireAnswerService,
                                              hide_score: _vm.hideQuestionScore,
                                              "disabled-interaction":
                                                _vm.currentQuestionDisabled,
                                              show_required_indicator:
                                                _vm.presentedField
                                                  .showRequiredIndicator,
                                              preview: _vm.preview,
                                              index: _vm.questionNumber(
                                                _vm.presentedFieldIndex
                                              ),
                                            },
                                            on: {
                                              revisionMarked:
                                                _vm.revisionMarked,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._.get(_vm.presentedField, "type") ===
                                      "multiple_choice"
                                        ? _c("MultipleChoiceAnswer", {
                                            ref: "currentSingleQuestion",
                                            attrs: {
                                              bus: _vm.eventBus,
                                              revisionMarked: _vm._.get(
                                                _vm.bookmarks,
                                                _vm.presentedField.id,
                                                false
                                              ),
                                              answer_id:
                                                _vm.questionnaireAnswer.id,
                                              user_id: _vm.user_id,
                                              item: _vm.presentedField,
                                              service:
                                                _vm.questionnaireAnswerService,
                                              hide_score: _vm.hideQuestionScore,
                                              "disabled-interaction":
                                                _vm.currentQuestionDisabled,
                                              show_required_indicator:
                                                _vm.presentedField
                                                  .showRequiredIndicator,
                                              preview: _vm.preview,
                                              index: _vm.questionNumber(
                                                _vm.presentedFieldIndex
                                              ),
                                            },
                                            on: {
                                              revisionMarked:
                                                _vm.revisionMarked,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._.get(_vm.presentedField, "type") ===
                                      "check_box"
                                        ? _c("CheckBoxAnswer", {
                                            ref: "currentSingleQuestion",
                                            attrs: {
                                              revisionMarked: _vm._.get(
                                                _vm.bookmarks,
                                                _vm.presentedField.id,
                                                false
                                              ),
                                              answer_id:
                                                _vm.questionnaireAnswer.id,
                                              user_id: _vm.user_id,
                                              item: _vm.presentedField,
                                              service:
                                                _vm.questionnaireAnswerService,
                                              hide_score: _vm.hideQuestionScore,
                                              "disabled-interaction":
                                                _vm.currentQuestionDisabled,
                                              show_required_indicator:
                                                _vm.presentedField
                                                  .showRequiredIndicator,
                                              preview: _vm.preview,
                                              index: _vm.questionNumber(
                                                _vm.presentedFieldIndex
                                              ),
                                            },
                                            on: {
                                              revisionMarked:
                                                _vm.revisionMarked,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._.get(_vm.presentedField, "type") ===
                                      "essay"
                                        ? _c("GapAnswer", {
                                            ref: "currentSingleQuestion",
                                            attrs: {
                                              revisionMarked: _vm._.get(
                                                _vm.bookmarks,
                                                _vm.presentedField.id,
                                                false
                                              ),
                                              answer_id:
                                                _vm.questionnaireAnswer.id,
                                              user_id: _vm.user_id,
                                              item: _vm.presentedField,
                                              service:
                                                _vm.questionnaireAnswerService,
                                              hide_score: _vm.hideQuestionScore,
                                              "disabled-interaction":
                                                _vm.currentQuestionDisabled,
                                              show_required_indicator:
                                                _vm.presentedField
                                                  .showRequiredIndicator,
                                              preview: _vm.preview,
                                              index: _vm.questionNumber(
                                                _vm.presentedFieldIndex
                                              ),
                                            },
                                            on: {
                                              revisionMarked:
                                                _vm.revisionMarked,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._.get(_vm.presentedField, "type") ===
                                      "essay"
                                        ? _c("EssayAnswer", {
                                            ref: "currentSingleQuestion",
                                            attrs: {
                                              revisionMarked: _vm._.get(
                                                _vm.bookmarks,
                                                _vm.presentedField.id,
                                                false
                                              ),
                                              answer_id:
                                                _vm.questionnaireAnswer.id,
                                              user_id: _vm.user_id,
                                              item: _vm.presentedField,
                                              service:
                                                _vm.questionnaireAnswerService,
                                              hide_score: _vm.hideQuestionScore,
                                              "disabled-interaction":
                                                _vm.currentQuestionDisabled,
                                              show_required_indicator:
                                                _vm.presentedField
                                                  .showRequiredIndicator,
                                              preview: _vm.preview,
                                              index: _vm.questionNumber(
                                                _vm.presentedFieldIndex
                                              ),
                                            },
                                            on: {
                                              revisionMarked:
                                                _vm.revisionMarked,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.activeSidebar
                        ? _c(
                            "div",
                            { staticClass: "absolute right-0 h-full" },
                            [
                              _c("font-awesome-icon", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip.top",
                                    value: {
                                      content:
                                        "Apresentar barra de questionário",
                                    },
                                    expression:
                                      "{\n              content: 'Apresentar barra de questionário'\n            }",
                                    modifiers: { top: true },
                                  },
                                ],
                                staticClass:
                                  "float-right ml-4 mr-2 cursor-pointer sidebar-left-button",
                                attrs: { icon: "chevron-left" },
                                on: {
                                  click: function ($event) {
                                    _vm.activeSidebar = true
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isSmallScreen &&
                      (_vm.showSingleQuestion || _vm.showSingleSection)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "_form-answer _mobile-navigation-bar",
                              style: _vm.mobileNavigationPosition,
                            },
                            [
                              _c(
                                "vx-card",
                                { staticClass: "w-full" },
                                [
                                  _c("question-pagination", {
                                    attrs: {
                                      "disable-next": _vm.disableNext,
                                      "disable-previous": _vm.disablePrevious,
                                      "pagination-info": _vm.paginationInfo,
                                      "show-done": _vm.doneAnyway,
                                    },
                                    on: {
                                      next: _vm.nextQuestion,
                                      previous: _vm.previousQuestion,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "vs-sidebar",
                    {
                      staticClass: "sidebar",
                      attrs: {
                        parent: _vm.$refs.parentSidebar,
                        "default-index": "1",
                        spacer: "",
                        "position-right": "",
                        "hidden-background": "",
                        value: _vm.activeSidebar,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          class: [
                            "grid col-span-1 mr-4 h-full",
                            _vm.activeSidebar
                              ? "sm:col-span-6 md:col-span-3 lg:col-span-3"
                              : "",
                          ],
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "fixed-content" },
                            [
                              _c(
                                "vs-card",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.activeSidebar,
                                      expression: "activeSidebar",
                                    },
                                  ],
                                  staticClass: "h-full",
                                  staticStyle: {
                                    "background-color": "lightgray",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex flex-col gap-1" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.activeSidebar,
                                              expression: "activeSidebar",
                                            },
                                          ],
                                          staticClass: "h-8",
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip.top",
                                                value: {
                                                  content:
                                                    "Esconder barra de questionário",
                                                },
                                                expression:
                                                  "{\n                      content: 'Esconder barra de questionário'\n                    }",
                                                modifiers: { top: true },
                                              },
                                            ],
                                            staticClass:
                                              "cursor-pointer sidebar-right-button float-left",
                                            attrs: { icon: "chevron-right" },
                                            on: {
                                              click: function ($event) {
                                                _vm.activeSidebar = false
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c("vs-card", [
                                            _c("div", [
                                              _c("table", [
                                                _vm.userName
                                                  ? _c(
                                                      "tr",
                                                      {
                                                        staticClass:
                                                          "sm:block flex flex-col gap-2",
                                                      },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "font-semibold sm:text-right",
                                                            staticStyle: {
                                                              "min-width":
                                                                "65px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "usuario-0"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass: "pl-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.truncate(
                                                                  _vm.userName
                                                                )
                                                              )
                                                            ),
                                                            _c("br"),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.userEmail
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "tr",
                                                  {
                                                    staticClass:
                                                      "sm:block flex flex-col gap-2",
                                                  },
                                                  [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "font-semibold sm:text-right",
                                                        staticStyle: {
                                                          "min-width": "65px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "questionario-0"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      { staticClass: "pl-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.truncate(
                                                              _vm.questionnaireName
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                          ]),
                                          _vm.started &&
                                          _vm.questionnaireAnswer
                                            .content_questionnaire &&
                                          _vm.questionnaireAnswer
                                            .content_questionnaire.duration > 0
                                            ? _c(
                                                "vx-card",
                                                {
                                                  attrs: {
                                                    collapseAction: "",
                                                    "header-style":
                                                      "flex items-start w-1/2 mr-auto",
                                                    isContentCollapsedProp:
                                                      _vm.countdownCardCollapsed,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "font-semibold",
                                                      attrs: { slot: "header" },
                                                      slot: "header",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t("cronometro")
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _vm.started &&
                                                      _vm.questionnaireAnswer
                                                        .content_questionnaire &&
                                                      _vm.questionnaireAnswer
                                                        .content_questionnaire
                                                        .duration > 0
                                                        ? _c("CountDown", {
                                                            attrs: {
                                                              duration:
                                                                _vm.questionnaireDuration,
                                                              show_seconds: true,
                                                            },
                                                            on: {
                                                              duration:
                                                                _vm.checkQuestionnaireDuration,
                                                              zero: () =>
                                                                (_vm.generalTimeZero = true),
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "vx-card",
                                            {
                                              staticClass: "mt-4",
                                              attrs: {
                                                collapseAction: "",
                                                "header-style":
                                                  "flex items-start w-1/2 mr-auto",
                                                isContentCollapsedProp:
                                                  _vm.questionCardCollapsed,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "font-semibold",
                                                  attrs: { slot: "header" },
                                                  slot: "header",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("questoes")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex flex-wrap gap-1",
                                                    },
                                                    _vm._l(
                                                      _vm.dataFields(),
                                                      function (question, i) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: `${i}${_vm.panelQuestionKey}`,
                                                            staticClass:
                                                              "relative",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                key: _vm.panelQuestionKey,
                                                              },
                                                              [
                                                                question.type !==
                                                                "section"
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        class: [
                                                                          _vm.questionStatusClass(
                                                                            question
                                                                          ),
                                                                          "grid justify-items-center items-center questionnaire-squares bg-grey cursor-pointer",
                                                                          {
                                                                            "questionnaire-square-selected":
                                                                              i ===
                                                                                _vm.presentedFieldIndex &&
                                                                              _vm.showSingleQuestion,
                                                                          },
                                                                        ],
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.checkQuestionNavigation(
                                                                                question.id,
                                                                                i
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.questionNumber(
                                                                                i
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                question.type ===
                                                                "section"
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        class: [
                                                                          {
                                                                            "questionnaire-square-selected":
                                                                              i ===
                                                                              _vm.presentedFieldIndex,
                                                                          },
                                                                          "grid justify-items-center items-center questionnaire-squares border-yellow cursor-pointer",
                                                                        ],
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.checkQuestionNavigation(
                                                                                question.id,
                                                                                i
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "S"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _vm.isBookmarked(
                                                              question
                                                            )
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "absolute question-square-bookmark-position",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "vs-icon",
                                                                      {
                                                                        staticClass:
                                                                          "revision-color",
                                                                        attrs: {
                                                                          icon: "bookmark",
                                                                          size: "1.20rem",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                  _c("vs-divider"),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vx-row sm:flex hidden mt-4",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "vx-col w-full flex",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex flex-wrap justify-center",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "flex items-center mr-4 mb-2",
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "h-3 w-3 inline-block rounded-full mr-2 bg-grey",
                                                                  }),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "nao-respondida"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "flex items-center mr-4 mb-2",
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "h-3 w-3 inline-block rounded-full mr-2 answer-saved",
                                                                  }),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "resposta-salva"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "flex items-center mr-4 mb-2",
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "h-3 w-3 inline-block rounded-full mr-2 bg-red-light",
                                                                  }),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "nao-salva"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "flex items-center mr-4 mb-2",
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "h-3 w-3 inline-block rounded-full mr-2 revision-bg-color",
                                                                  }),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "revisao"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "flex items-center mr-4 mb-2",
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "h-3 w-3 inline-block rounded-full mr-2 bg-yellow",
                                                                  }),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "sessoes"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("vs-divider"),
                                                  _vm.started &&
                                                  !_vm.showSingleQuestion
                                                    ? _c(
                                                        "vs-row",
                                                        [
                                                          _c(
                                                            "vs-col",
                                                            [
                                                              _c(
                                                                "question-pagination",
                                                                {
                                                                  attrs: {
                                                                    reduced:
                                                                      _vm.isSmallScreen,
                                                                    "disable-next":
                                                                      _vm.disableNext,
                                                                    "disable-previous":
                                                                      _vm.disablePrevious,
                                                                    "pagination-info":
                                                                      _vm.paginationInfo,
                                                                    "show-done":
                                                                      _vm.doneAnyway,
                                                                  },
                                                                  on: {
                                                                    next: _vm.nextQuestion,
                                                                    previous:
                                                                      _vm.previousQuestion,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.started &&
                                                  _vm.showSingleQuestion
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex w-full pb-4 pt-4",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex flex-col items-center justify-center w-full",
                                                            },
                                                            [
                                                              _c(
                                                                "question-pagination",
                                                                {
                                                                  attrs: {
                                                                    reduced:
                                                                      _vm.isSmallScreen,
                                                                    "disable-next":
                                                                      _vm.disableNext,
                                                                    "disable-previous":
                                                                      _vm.disablePrevious,
                                                                    "pagination-info":
                                                                      _vm.paginationInfo,
                                                                    "show-done":
                                                                      _vm.doneAnyway,
                                                                  },
                                                                  on: {
                                                                    next: _vm.nextQuestion,
                                                                    previous:
                                                                      _vm.previousQuestion,
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  class: [
                                                                    _vm.showQuestionTimer
                                                                      ? "flex"
                                                                      : "hidden",
                                                                    "pt-2 gap-1 items-start justify-center font-bold",
                                                                  ],
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "10px",
                                                                  },
                                                                },
                                                                [
                                                                  _c("label", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "tempo-questao"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "simple-timer",
                                                                    {
                                                                      ref: "simpleTimer",
                                                                      attrs: {
                                                                        value:
                                                                          _vm.questionTimer,
                                                                        "auto-start":
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        ratio:
                                                                          _vm.checkQuestionTimeRatio,
                                                                        zero: _vm.currentQuestionTimerZeroed,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.questionTimer,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.questionTimer =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "questionTimer",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    [
                                                      _vm.questionnaireCanPause &&
                                                      _vm.started
                                                        ? _c(
                                                            "vs-button",
                                                            {
                                                              staticClass:
                                                                "bg-white w-full mt-4",
                                                              attrs: {
                                                                icon: "pan_tool",
                                                                type: "border",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.showPauseForm = true
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "solicitar-pausa"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm.supportInProgress ||
                                          _vm.userMediaStream !== null
                                            ? _c(
                                                "vs-card",
                                                {
                                                  staticClass: "mt-4",
                                                  attrs: {
                                                    collapseAction: "",
                                                    isContentCollapsedProp:
                                                      _vm.monitorCardCollapsed,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "font-semibold",
                                                      attrs: { slot: "header" },
                                                      slot: "header",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "monitoramento"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "grid justify-items-center items-center",
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm.userMediaStream !==
                                                        null
                                                          ? _c("video", {
                                                              staticClass:
                                                                "video-player transform",
                                                              attrs: {
                                                                id: "video",
                                                                autoplay:
                                                                  "autoplay",
                                                                muted: "",
                                                                disablePictureInPicture:
                                                                  "",
                                                              },
                                                              domProps: {
                                                                muted: true,
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ]),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex justify-items-center items-center",
                                                        },
                                                        [
                                                          _vm.showSupportPhoneCall &&
                                                          _vm.isProctoringLiveChat
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  key: _vm.supportKey,
                                                                  staticClass:
                                                                    "open-call-support flex items-center",
                                                                },
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      class: [
                                                                        "cursor-pointer",
                                                                        _vm.waitingSupport
                                                                          ? "bell"
                                                                          : "",
                                                                      ],
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "18px",
                                                                        },
                                                                      attrs: {
                                                                        icon: "phone",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.openCallSupport()
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pl-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.waitingSupport
                                                                              ? "Aguardando monitor..."
                                                                              : "Ligar para monitor"
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.isProctoringLiveChat &&
                                                          _vm.supportInProgress
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "close-call-support flex items-center",
                                                                },
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      staticClass:
                                                                        "cursor-pointer",
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "18px",
                                                                        },
                                                                      attrs: {
                                                                        icon: "phone",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.closeCallSupport()
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pl-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "desligar-chamada"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _vm.supportInProgress
                                                        ? _c("vs-divider")
                                                        : _vm._e(),
                                                      _vm.supportInProgress
                                                        ? _c("div", [
                                                            _c("b", [
                                                              _vm._v(
                                                                "Monitor:"
                                                              ),
                                                            ]),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .supportInProgress
                                                                    .monitorName
                                                                ) +
                                                                " "
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm.supportInProgress
                                                        ? _c("div", {
                                                            ref: "monitoVideoPlayerDiv",
                                                            staticClass:
                                                              "video-player",
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        position: "absolute",
                        bottom: "10px",
                      },
                    },
                    [
                      _vm.started
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "w-full items-center justify-items-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center gap-2 mt-2",
                                },
                                [
                                  _c("logo", {
                                    staticStyle: { "max-width": "100px" },
                                    attrs: {
                                      public: "",
                                      institutionLogo: _vm.organizationLogo,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.started && _vm.hasTemplate
            ? _c("div", { staticClass: "min-h-screen" }, [
                _c("iframe", {
                  ref: "templateRef",
                  staticClass: "iframe-template",
                  attrs: {
                    src: _vm.questionnaireTemplatePath,
                    frameborder: "0",
                    height: "100%",
                    width: "100%",
                  },
                }),
              ])
            : _vm._e(),
          _c(
            "vs-popup",
            {
              staticStyle: { "z-index": "20000000" },
              attrs: {
                title: _vm.$t("habilitar-monitoramento-proctoring"),
                active: _vm.showProctoringInit,
                fullscreen: "true",
                "button-close-hidden": true,
              },
              on: {
                "update:active": function ($event) {
                  _vm.showProctoringInit = $event
                },
              },
            },
            [
              _c("div", { staticClass: "h-full proctoring-image-guide" }, [
                !_vm.isValidBrowser
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "grid grid-flow-row auto-rows-max" },
                        [
                          _c("div", [
                            _c("div", { staticClass: "vx-row pb-4" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-col w-full flex items-center justify-center",
                                },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "o-seu-navegador-capitalize-browsername-versao-browserversion-nao-e-compativel",
                                            [
                                              _vm.capitalize(_vm.browserName()),
                                              _vm.browserVersion(),
                                            ]
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "vx-row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vx-col w-full flex items-center justify-center",
                                },
                                [
                                  _c("h6", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "favor-atualizar-para-a-versao-mais-recente-ou-utilizar-um-dos-navegadores-abaixo-em-suas-respectivas-versoes"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "m-4 pt-6" }, [
                            _c(
                              "div",
                              {
                                staticClass: "grid grid-cols-1 md:grid-cols-3",
                              },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-full flex items-center justify-center",
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          src: require("@/assets/images/pages/proctoring/firefox-logo.png"),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "flex items-center justify-center mt-2",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "firefox-versao-66-ou-superior"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-full flex items-center justify-center",
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          src: require("@/assets/images/pages/proctoring/chrome-logo.png"),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "flex items-center justify-center mt-2",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("chrome-versao-72-ou-superior")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-full flex items-center justify-center",
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          src: require("@/assets/images/pages/proctoring/opera-logo.png"),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "flex items-center justify-center mt-2",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("opera-versao-60-ou-superior")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c("vs-divider"),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-full flex items-center justify-center",
                            },
                            [
                              _c(
                                "vs-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.showProctoringInit = false
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("ok")) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.isValidBrowser &&
                !_vm.screenShareEnabled &&
                !_vm.userMediaEnabled &&
                !_vm.captureScreenshareError
                  ? _c("div", { staticClass: "w-full flex" }, [
                      _c("div", { staticClass: "w-full" }, [
                        _c("div", { staticClass: "flex mb-4" }, [
                          _c(
                            "div",
                            { staticClass: "w-full" },
                            [
                              _c(
                                "vs-alert",
                                {
                                  staticClass: "mb-4 h-auto",
                                  attrs: {
                                    active: "true",
                                    icon: "new_releases",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "este-questionario-sera-monitorado-utilizando"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "proctoring.by_remote_monitoring"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "e-os-dados-da-sua-tela-camera-e-microfone-serao-capturados-e-enviados-para-posterior-analise-de-autenticidade"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "vs-alert",
                                {
                                  staticClass: "mb-4 h-auto",
                                  attrs: {
                                    active: "true",
                                    icon: "new_releases",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "nenhuma-imagem-sera-compartilhada-com-terceiros-e-somente-serao-utilizadas-para-comprovacao-da-autenticidade-das-informacoes-fornecidas"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "vs-alert",
                                {
                                  staticClass: "mb-4 h-auto",
                                  attrs: {
                                    active: "true",
                                    icon: "new_releases",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "as-imagens-somente-ficarao-armazenadas-no-sistema-por-um-prazo-maximo-de-14-dias-sendo-totalmente-apagadas-apos-este-periodo"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "vs-alert",
                                {
                                  staticClass: "mb-4 h-auto",
                                  attrs: {
                                    active: "true",
                                    icon: "new_releases",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "apos-selecionar-o-botao-habilitar-sera-apresentada-uma-janela-de-confirmacao-semelhante-a-imagem-abaixo"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "vs-alert",
                                {
                                  staticClass: "mb-4 h-auto",
                                  attrs: {
                                    active: "true",
                                    icon: "new_releases",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "escolha-a-opcao-a-tela-inteira-e-selecione-o-botao-compartilhar"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "vs-alert",
                                {
                                  staticClass: "mb-4 h-auto",
                                  attrs: {
                                    active: "true",
                                    icon: "new_releases",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("agora-voce-iniciara-sua-prova")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "grid grid-cols-1 sm:grid-cols-2" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex items-center justify-center",
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    "max-width": "90%",
                                    "max-height": "300px",
                                  },
                                  attrs: { src: _vm.screenShareImage },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-center justify-center mt-4",
                              },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "mr-2",
                                    attrs: {
                                      icon: "icon icon-monitor",
                                      "icon-pack": "feather",
                                    },
                                    on: { click: _vm.enableScreenShare },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("habilitar")) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "vs-button",
                                  {
                                    attrs: { type: "border", color: "danger" },
                                    on: { click: _vm.cancelProctoringInit },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("action.cancel")))]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.isValidBrowser &&
                !_vm.screenShareEnabled &&
                !_vm.userMediaEnabled &&
                _vm.captureScreenshareError
                  ? _c(
                      "div",
                      { staticClass: "h-full" },
                      [
                        _c(
                          "vs-row",
                          { staticClass: "mb-2" },
                          [
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-type": "flex",
                                  "vs-justify": "start",
                                  "vs-align": "start",
                                  "vs-w": "12",
                                },
                              },
                              [
                                _c("div", [
                                  _c(
                                    "ul",
                                    {
                                      staticClass: "pl-4",
                                      staticStyle: {
                                        "list-style-type": "disc",
                                      },
                                    },
                                    [
                                      _c(
                                        "vs-alert",
                                        {
                                          staticClass: "mb-4 h-auto",
                                          attrs: {
                                            active: "true",
                                            icon: "new_releases",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "houve-um-erro-para-iniciar-a-captura-de-tela-favor-verificar-se-nao-ha-bloqueio-de-permissao-de-acordo-com-a-imagem-abaixo"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "vs-alert",
                                        {
                                          staticClass: "mb-4 h-auto",
                                          attrs: {
                                            active: "true",
                                            icon: "new_releases",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "caso-haja-bloqueio-para-o-compartilhamento-remova-o-bloqueio-e-tente-novamente"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("div", {
                                        staticClass: "mt-2 mb-4",
                                        staticStyle: { color: "red" },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.captureScreenshareError
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "vs-row",
                          { staticClass: "margin-bottom: 25px;" },
                          [
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-type": "flex",
                                  "vs-justify": "center",
                                  "vs-align": "center",
                                  "vs-w": "12",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: { "max-width": "100%" },
                                  attrs: { src: _vm.screenShareErrorImage },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("vs-divider"),
                        _c(
                          "vs-row",
                          [
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-type": "flex",
                                  "vs-justify": "center",
                                  "vs-align": "center",
                                  "vs-w": "12",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-center gap-4",
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              icon: "icon icon-monitor",
                                              "icon-pack": "feather",
                                            },
                                            on: {
                                              click: _vm.enableScreenShare,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("habilitar")) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            attrs: {
                                              type: "border",
                                              color: "danger",
                                            },
                                            on: {
                                              click: _vm.cancelProctoringInit,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("action.cancel"))
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isValidBrowser &&
                _vm.screenShareEnabled &&
                !_vm.userMediaEnabled &&
                !_vm.captureUserMediaError
                  ? _c("div", { staticClass: "h-full" }, [
                      _c("div", { staticClass: "w-full" }, [
                        _c("div", { staticClass: "flex mb-4" }, [
                          _c(
                            "div",
                            { staticClass: "w-full" },
                            [
                              _c(
                                "vs-alert",
                                {
                                  staticClass: "mb-4 h-auto",
                                  attrs: {
                                    active: "true",
                                    icon: "new_releases",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "nesta-etapa-sera-necessario-habilitar-a-captura-de-video-da-camera-e-o-audio-do-microfone"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "vs-alert",
                                {
                                  staticClass: "mb-4 h-auto",
                                  attrs: {
                                    active: "true",
                                    icon: "new_releases",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "apos-selecionar-o-botao-habilitar-sera-apresentada-uma-janela-de-confirmacao-semelhante-a-imagem-abaixo"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "vs-alert",
                                {
                                  staticClass: "mb-4 h-auto",
                                  attrs: {
                                    active: "true",
                                    icon: "new_releases",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "e-muito-importante-que-habilite-a-camera-frontal-e-um-microfone-que-esteja-em-funcionamento-sera-necessario-usar-estes-dispositivos-posteriormente-para-validar-seu-acesso"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "vs-alert",
                                {
                                  staticClass: "mb-4 h-auto",
                                  attrs: {
                                    active: "true",
                                    icon: "new_releases",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "caso-nao-apareca-uma-tela-para-habilitar-a-captura-semelhante-a-imagem-abaixo-possivelmente-o-compartilhamento-esteja-bloqueado-selecione-a-opcao-de-compartilhamento-com-base-na-imagem-abaixo-localizado-proximo-a-barra-de-enderecos-e-habilite-a-opcao-para-compartilhar-a-camera-e-microfone"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "grid grid-cols-1 sm:grid-cols-2" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex items-center justify-center",
                              },
                              [
                                _c("img", {
                                  staticStyle: { "max-width": "100%" },
                                  attrs: { src: _vm.userMediaImage },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-center justify-center mt-4",
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          icon: "icon icon-monitor",
                                          "icon-pack": "feather",
                                        },
                                        on: { click: _vm.enableUserMedia },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("habilitar")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        attrs: {
                                          type: "border",
                                          color: "danger",
                                        },
                                        on: { click: _vm.cancelProctoringInit },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("action.cancel")))]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.isValidBrowser &&
                _vm.screenShareEnabled &&
                !_vm.userMediaEnabled &&
                _vm.captureUserMediaError
                  ? _c(
                      "div",
                      { staticClass: "h-full" },
                      [
                        _c(
                          "vs-row",
                          { staticClass: "mb-2" },
                          [
                            _c("vs-col", {
                              attrs: {
                                "vs-type": "flex",
                                "vs-justify": "start",
                                "vs-align": "start",
                                "vs-w": "12",
                              },
                            }),
                            _c(
                              "ul",
                              {
                                staticClass: "pl-4",
                                staticStyle: { "list-style-type": "disc" },
                              },
                              [
                                _c("li", { staticClass: "mb-2" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "nao-foi-possivel-habilitar-a-camera-e-ou-microfone-por-favor-verifique-se-estes-dispositivos-estao-funcionando-corretamente-e-as-permissoes-estao-liberadas"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm.isMacOS
                                  ? _c("li", { staticClass: "mb-2" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "verifique-se-o-seu-navegador-possui-as-permissoes-necessarias-em"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c("b", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "x-apple.systempreferences:com.apple.preference.security?Privacy_Camera",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "preferencias-greater-than-seguranca-e-privacidade-greater-than-privacidade"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("marque-o-seu-navegador-em")
                                        ) + " "
                                      ),
                                      _c("b", [
                                        _vm._v(_vm._s(_vm.$t("camera"))),
                                      ]),
                                      _vm._v(" e "),
                                      _c("b", [
                                        _vm._v(_vm._s(_vm.$t("microfone"))),
                                      ]),
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "de-acordo-com-a-imagem-abaixo"
                                          )
                                        ) + " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "vs-row",
                          { staticClass: "margin-bottom: 25px;" },
                          [
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-type": "flex",
                                  "vs-justify": "center",
                                  "vs-align": "center",
                                  "vs-w": "12",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-center",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mr-2",
                                      staticStyle: { "max-width": "30%" },
                                      attrs: { src: _vm.userMediaErrorImage },
                                    }),
                                    _vm.isMacOS
                                      ? _c("img", {
                                          staticClass: "mr-2",
                                          staticStyle: { "max-width": "30%" },
                                          attrs: {
                                            src: require("@/assets/images/pages/proctoring/maccatalina_permission_cam.jpeg"),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.isMacOS
                                      ? _c("img", {
                                          staticStyle: { "max-width": "30%" },
                                          attrs: {
                                            src: require("@/assets/images/pages/proctoring/maccatalina_permission_mic.jpeg"),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "vs-row",
                          {
                            staticStyle: {
                              bottom: "25px",
                              "margin-left": "-25px",
                              position: "fixed !important",
                            },
                          },
                          [
                            _c("vs-col", {
                              attrs: {
                                "vs-type": "flex",
                                "vs-justify": "center",
                                "vs-align": "center",
                                "vs-w": "12",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-center justify-center mt-4 w-full",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "grid grid-cols-2 gap-4" },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              icon: "icon icon-monitor",
                                              "icon-pack": "feather",
                                            },
                                            on: { click: _vm.enableUserMedia },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("habilitar")) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            attrs: {
                                              type: "border",
                                              color: "danger",
                                            },
                                            on: {
                                              click: _vm.cancelProctoringInit,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("action.cancel"))
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isValidBrowser &&
                _vm.screenShareEnabled &&
                _vm.userMediaEnabled
                  ? _c("div", { staticClass: "h-full" }, [
                      _c(
                        "div",
                        {
                          staticClass: "grid grid-cols-1 sm:grid-cols-2 h-full",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "h-full",
                              staticStyle: {
                                "border-right": "1px solid #dadce0",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-center mt-4",
                                },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(_vm.$t("tire-uma-foto-documento"))
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-center mt-4",
                                },
                                [
                                  _c("video", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.userMediaStream !== null &&
                                          this.showProctoringInit &&
                                          _vm.userPicture === null,
                                        expression:
                                          "userMediaStream !== null && this.showProctoringInit && userPicture === null",
                                      },
                                    ],
                                    staticClass:
                                      "video-player-document transform w-full",
                                    attrs: {
                                      id: "video-document",
                                      muted: "",
                                      disablePictureInPicture: "",
                                    },
                                    domProps: { muted: true },
                                  }),
                                  _c("canvas", {
                                    staticStyle: { display: "none" },
                                    attrs: { id: "picture" },
                                  }),
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.userPicture !== null,
                                        expression: "userPicture !== null",
                                      },
                                    ],
                                    staticClass: "transform w-full sm:w-1/2",
                                    attrs: { id: "picture_img" },
                                  }),
                                ]
                              ),
                              _vm.getDeviceVideoError !== null
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-center mt-4",
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: { "max-width": "300px" },
                                        attrs: {
                                          src: require("@/assets/images/no_image_placeholder.png"),
                                          alt: "$t('no-camera')",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-center mt-4",
                                },
                                [
                                  _vm.userPicture === null &&
                                  _vm.getDeviceVideoError === null
                                    ? _c(
                                        "vs-button",
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            icon: "icon icon-camera",
                                            "icon-pack": "feather",
                                          },
                                          on: { click: _vm.takePicture },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("capturar-foto")) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.userPicture !== null
                                    ? _c(
                                        "vs-button",
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            type: "border",
                                            color: "danger",
                                            icon: "icon icon-x-circle",
                                            "icon-pack": "feather",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.userPicture = null
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("tentar-novamente")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-center mt-4",
                                },
                                [
                                  _c("vs-divider", [
                                    _vm._v(
                                      _vm._s(_vm.$t("validacao-de-microfone"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "grid grid-rows-2 mt-4" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-center",
                                    },
                                    [
                                      _c(
                                        "vs-progress",
                                        {
                                          attrs: {
                                            percent: _vm.micVolume,
                                            color: "success",
                                          },
                                        },
                                        [_vm._v("success")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center justify-center",
                                    },
                                    [
                                      !_vm.micSuccess
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "mic-config mt-2 text-center",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "fale-algo-em-voz-alta-para-validar-a-configuracao-do-seu-microfone"
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.micSuccess
                                        ? _c(
                                            "span",
                                            { staticClass: "mic-succes mt-2" },
                                            [
                                              _vm._v(
                                                _vm._s(`${_vm.$t("sucesso")}!`)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "grid grid-rows-6 p-4" }, [
                            _c("div", { staticClass: "row-span-2" }, [
                              _vm.videoDevices.length > 0
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "vs-select",
                                        {
                                          staticClass: "w-full",
                                          attrs: {
                                            label: _vm.$t(
                                              "entradas-de-video-camera"
                                            ),
                                          },
                                          on: { input: _vm.enableUserMedia },
                                          model: {
                                            value: _vm.selectedVideoDeviceId,
                                            callback: function ($$v) {
                                              _vm.selectedVideoDeviceId = $$v
                                            },
                                            expression: "selectedVideoDeviceId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.videoDevices,
                                          function (device, index) {
                                            return _c("vs-select-item", {
                                              key: index,
                                              attrs: {
                                                value: device.deviceId,
                                                text: device.label,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm.getDeviceVideoError !== null
                                        ? _c(
                                            "vs-alert",
                                            {
                                              staticClass: "mt-2 h-auto",
                                              attrs: {
                                                active: "true",
                                                color: "danger",
                                                icon: "new_releases",
                                              },
                                            },
                                            [
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.getDeviceVideoError
                                                  ),
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.audioDevices && _vm.audioDevices.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "mt-4" },
                                    [
                                      _c(
                                        "vs-select",
                                        {
                                          staticClass: "w-full",
                                          attrs: {
                                            label: _vm.$t(
                                              "entradas-de-audio-microfone"
                                            ),
                                          },
                                          on: { input: _vm.enableUserMedia },
                                          model: {
                                            value: _vm.selectedAudioDeviceId,
                                            callback: function ($$v) {
                                              _vm.selectedAudioDeviceId = $$v
                                            },
                                            expression: "selectedAudioDeviceId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.audioDevices,
                                          function (device, index) {
                                            return _c("vs-select-item", {
                                              key: index,
                                              attrs: {
                                                value: device.deviceId,
                                                text: device.label,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm.getDeviceAudioError !== null
                                        ? _c(
                                            "vs-alert",
                                            {
                                              staticClass: "mt-2 h-auto",
                                              attrs: {
                                                active: "true",
                                                color: "danger",
                                                icon: "new_releases",
                                              },
                                            },
                                            [
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.getDeviceAudioError
                                                  ),
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              { staticClass: "row-span-4" },
                              [
                                _c("vs-divider"),
                                _c(
                                  "vs-alert",
                                  {
                                    staticClass: "mb-4 text-warning h-auto",
                                    attrs: {
                                      active: "true",
                                      icon: "new_releases",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "nao-e-permitido-o-uso-de-fones-de-ouvido-ou-similares"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "vs-alert",
                                  {
                                    staticClass: "mb-4 text-warning h-auto",
                                    attrs: {
                                      active: "true",
                                      icon: "new_releases",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "nao-mantenha-conversa-com-pessoas-ao-redor"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "vs-alert",
                                  {
                                    staticClass: "mb-4 h-auto",
                                    attrs: {
                                      active: "true",
                                      icon: "new_releases",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "desejamos-uma-otima-avaliacao"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("vs-divider"),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-center gap-4 mt-4",
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            staticClass:
                                              "mr-2 start-questionnaire",
                                            attrs: {
                                              icon: "play_arrow",
                                              disabled:
                                                _vm.userPicture === null ||
                                                !_vm.micSuccess,
                                            },
                                            on: {
                                              click: _vm.startQuestionnaire,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("news_start_at")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            attrs: {
                                              type: "border",
                                              color: "danger",
                                            },
                                            on: {
                                              click: _vm.cancelProctoringInit,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("action.cancel"))
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "vs-prompt",
            {
              attrs: {
                color: "warning",
                title: _vm.$t("atencao-0"),
                type: "confirm",
                "cancel-text": "Encerrar avaliação",
                "accept-text": "Voltar para tela cheia",
                active: _vm.fullscreenActivePrompt,
              },
              on: {
                accept: _vm.requestFullscreen,
                cancel: _vm.finishInterval,
                close: function ($event) {
                  _vm.fullscreenActivePrompt = true
                },
                "update:active": function ($event) {
                  _vm.fullscreenActivePrompt = $event
                },
              },
            },
            [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.forceFullscreenMessage) },
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "_form-answer __navigation-warning-popup",
          attrs: {
            title: _vm.$t("atencao-0"),
            active: _vm.showQuestionNavigationWarning,
            buttonCloseHidden: true,
          },
        },
        [
          _c("span", { staticClass: "text-lg font-medium" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "este-questionario-nao-permite-voltar-para-a-questao-tem-certeza-que-deseja-avancar"
                  )
                ) +
                " "
            ),
          ]),
          _c(
            "div",
            {
              class: [
                "flex",
                "pt-6",
                _vm.warnedDisallowReturn ? "text-primary" : "text-danger",
              ],
            },
            [
              _c("vs-checkbox", {
                model: {
                  value: _vm.warnedDisallowReturn,
                  callback: function ($$v) {
                    _vm.warnedDisallowReturn = $$v
                  },
                  expression: "warnedDisallowReturn",
                },
              }),
              _c("label", [
                _vm._v(_vm._s(_vm.$t("entendi-nao-mostrar-novamente")) + " "),
              ]),
            ],
            1
          ),
          _c("vs-divider", { staticClass: "w-full" }),
          _c(
            "div",
            { staticClass: "flex gap-2 w-full justify-end" },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.navigationWarningProcceed },
                },
                [_vm._v(_vm._s(_vm.$t("avancar")))]
              ),
              _c(
                "vs-button",
                {
                  attrs: { color: "danger", type: "border" },
                  on: { click: _vm.navigationWarningCancel },
                },
                [_vm._v(_vm._s(_vm.$t("action.cancel")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("justificar-atraso"),
            active: _vm.promptEntranceJustification,
            "button-close-hidden": true,
          },
        },
        [
          _c("justification-form", {
            attrs: {
              showCancelButton: false,
              alerts: [
                {
                  text: "Você está atrasado para iniciar o questionário, justifique o motivo.",
                  color: "rgb(231, 154, 23)",
                  title: "Alerta",
                  classes: "text-warning h-full",
                },
              ],
            },
            on: { save: _vm.emitEntranceJustificationEvent },
          }),
        ],
        1
      ),
      _vm.showPausedOverlay
        ? _c("pause-overlay", {
            on: { leaveQuestionnairePause: _vm.leavePause },
          })
        : _vm._e(),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("solicitar-pausa"),
            active: _vm.showPauseForm,
          },
          on: {
            "update:active": function ($event) {
              _vm.showPauseForm = $event
            },
          },
        },
        [
          _c("justification-form", {
            attrs: {
              alerts: [
                {
                  text: "Ao iniciar uma pausa justificada, se o questionário for com tempo, o período/tempo da sua avaliação não é alterado.",
                  color: "rgb(231, 154, 23)",
                  title: "Alerta",
                  classes: "text-warning h-full",
                },
              ],
            },
            on: {
              cancel: function ($event) {
                _vm.showPauseForm = false
              },
              save: _vm.emitPauseEvent,
            },
          }),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t(
              "voicecontrolactivated-instrucoes-ativacao-controle-de-voz"
            ),
            active: _vm.showVoiceControlConfirmation,
          },
          on: {
            "update:active": function ($event) {
              _vm.showVoiceControlConfirmation = $event
            },
          },
        },
        [
          !_vm.voiceControlActivated
            ? _c("div", { staticClass: "text-lg font-medium" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("deseja-ativar-o-controle-por-voz")) + " "
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "text-lg font-normal mt-2 mb-4" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "usando-comandos-de-voz-e-possivel-realizar-todo-o-processo-com-instrucoes-usando-a-voz-leia-os-comandos-abaixo-ou-diga-ler-instrucoes-apos-ativar-para-que-o-sistema-leia-as-instrucoes"
                  )
                ) +
                " "
            ),
          ]),
          !_vm.voiceControlActivated
            ? _c(
                "div",
                { staticClass: "flex gap-2 w-full justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.voiceControlStateChange(true)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("sim-ativar")))]
                  ),
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "danger", type: "border" },
                      on: {
                        click: function ($event) {
                          return _vm.voiceControlStateChange(false)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("nao")))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("vs-divider", { staticClass: "w-full" }, [
            _vm._v(" " + _vm._s(_vm.$t("instrucoes")) + " "),
          ]),
          _c(
            "vs-table",
            {
              attrs: { data: _vm.voiceCommands },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ data }) {
                    return _vm._l(data, function (tr, i) {
                      return _c(
                        "vs-tr",
                        { key: i },
                        [
                          _c("vs-td", [_vm._v(_vm._s(data[i].value))]),
                          _c("vs-td", [_vm._v(_vm._s(data[i].description))]),
                        ],
                        1
                      )
                    })
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", { attrs: { width: "35%" } }, [
                    _vm._v(_vm._s(_vm.$t("comando"))),
                  ]),
                  _c("vs-th", { attrs: { width: "15%" } }, [
                    _vm._v(_vm._s(_vm.$t("description"))),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("vx-tour", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeSidebar,
            expression: "activeSidebar",
          },
        ],
        attrs: {
          name: "questionnaireAnswerSidebar",
          "stop-text": "OK",
          auto: true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }