var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-popup",
    {
      ref: "vspopup-qrcode",
      attrs: {
        title: _vm.$t("document_person.send"),
        active: _vm.visible,
        id: "popup-qrcode",
      },
      on: {
        "update:active": function ($event) {
          _vm.visible = $event
        },
        close: _vm.closePopup,
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "grid items-center flex flex-wrap justify-center justify-items-center gap-1",
        },
        [
          _c("div", { staticClass: "p-4" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("document_person.qrcode_description"))),
            ]),
          ]),
          _c("div", [
            _c("img", {
              staticClass: "border-t-0 border-b-0 border-r-0",
              staticStyle: { "max-width": "200px", "max-height": "200px" },
              attrs: {
                src: _vm.qrCodeBase64,
                alt: "QR Code Document Person Upload",
              },
            }),
          ]),
          _c("div", [
            _c("span", { staticClass: "text-sm" }, [
              _vm._v(_vm._s(_vm.$t("document_person.experis_at"))),
            ]),
            _c(
              "div",
              [
                _c("count-down", {
                  key: _vm.countDownQrCode,
                  attrs: { duration: _vm.time },
                  on: { zero: _vm.timeExperisQrCodeValidation },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "flex pt-2 items-center text-sm float-right flex-end gap-1",
        },
        [
          _c("font-awesome-icon", {
            staticClass: "h-3 w-3",
            attrs: { icon: "lock" },
          }),
          _c("span", [_vm._v(_vm._s(_vm.$t("secure_connection")))]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }